import React from "react"
import { Link } from "gatsby"

import HeaderResources from "../../../components/header_resources"

export default function TheLittleBook() {
  const heroTitle = "The Little Book That Builds Wealth – Pat Dorsey (PD)"

  return (
    <HeaderResources heroTitle={heroTitle} layout="1Column">
      <div class="w-100 mb-5 sm:mmb-5">
        <Link class="back--home" to="/resources/book-summaries">
          <i class="fas fa-chevron-left"></i> <span>Back to Book Summaries</span>
        </Link>
      </div>

      <div class="innerpage--content innerpage--content-sm blog--details m-0 p-0">
        <h2 class="h2 h2-sm">{heroTitle}</h2>
        <p>
          This book is like a refresher course on some basics about investments with moat investing
          being the central theme. I have set out below a summary, key takeaways / learning
          outcomes, some ramblings and unanswered questions.
        </p>

        <h3>Sets out the game plan for investing in very lucid steps as follows:</h3>
        <ol>
          <li>Identify businesses that can generate above average profits for many years;</li>
          <li>
            Wait until the shares of those business trade for less than their intrinsic value, and
            then buy;
          </li>
          <li>
            Hold them until the business deteriorates, the shares become overvalued (I would add
            egregiously before overvalued) or you find better investments.
          </li>
        </ol>

        <p>
          Begins explaining about moats by appealing to common sense by saying that people pay more
          for something that is more durable. Similarly, a company with a moat is worth more today
          because it will generate economic profits for a longer stretch of time. Further, thinking
          about and building the concept of economic moats into investing strategies helps in
          enforcing investment discipline (one is less likely to overpay for a company that has no
          competitive advantage) and the odds of permanent capital impairment are greatly reduced.{" "}
        </p>
        <p>
          PD also suggests being aware of mistaken moats. Leadership is not a moat, for instance, in
          highly competitive markets. He asks investors to not ask whether a firm has a high market
          share but rather how the firm achieved that share. This ties into what Taleb said in
          Fooled by Randomness regarding thinking about the “generator” of the outcome.
        </p>

        <h3>Explains the following sources of moats:</h3>
        <ul>
          <li>
            <strong>Intangible Assets</strong> – Brands, patents, regulatory licenses
            <ul>
              <li>
                Brand creates an economic moat only if it increases the consumer’s willingness to
                pay more for the product/service or increases consumer captivity.
              </li>
              <li>
                One should be wary of any firm that relies on a smaller number of patented products
                for its profits, what one needs to look for is companies that have a diverse
                portfolio of patents with a track record of innovation.
              </li>
              <li>
                Regulatory licenses help create an economic moat when because of the license the
                company can price like a monopoly without being regulated like one.
              </li>
            </ul>
          </li>
          <li>
            <strong>Switching Costs</strong> – they are a valuable competitive advantage because a
            company can extract more money out of its customers if those customers are unlikely to
            move to a competitor (prime example being banks).
          </li>
          <li>
            <strong>Network Effect</strong> – value of the company’s product/service increases with
            the number of users. Network effect is much more common among businesses based on
            information or knowledge transfer than among businesses based on physical capital.
            Microsoft or Amex or Stock / Futures Exchanges for instance.{" "}
          </li>
          <li>
            <strong>Cost Advantages</strong> – companies can also dig moats around their businesses
            by having sustainable lower costs than the competition. This advantage is, however,
            prone to disappearing rather quickly. This moat matters most in industries where price
            is a big part of a customer’s purchase decision. Basically commodity type companies.
            Cost advantages primarily arise because of cheaper processes, better locations and
            unique resources.
          </li>
          <li>
            <strong>Size Advantage</strong> – a big fish in a small pond is better than a bigger
            fish in a bigger pond so one needs to focus on the fish to pond ratio not the absolute
            size of the fish. Delivering stuff more cheaply than anyone else can be very profitable.
          </li>
        </ul>

        <p>
          Given that people and companies are not static organisms, moats are susceptible to being
          eroded and accordingly, one should be early to read weakening of competitive advantage.
          Technology disruption, consolidation of a once-fragmented group of customers or entry of
          an irrational competitor into an industry, investing heavily in industries/ areas where
          the company has no competitive advantage can all lead to destruction of moats.
        </p>
        <p>
          Finding moats isn’t easy so one has better odds of hunting for ideas in industries where
          managers need to jump only one-foot bars to succeed rather than where barriers to success
          are much higher. Moats in telecom companies are very narrow or non-existent and those in
          companies which sell health care products like drugs or medical devices are higher.{" "}
        </p>

        <h3>Prescribes the following steps to identify moats:</h3>
        <ul>
          <li>
            Check the historical track record of the company in terms of generating returns on
            capital. Strong returns indicate that the company may have a moat;
          </li>
          <li>Identity how it may have a moat – pointers provided above;</li>
          <li>Once identified, think about how strong it is and how long will it last.</li>
        </ul>

        <p>
          PD suggests betting on horses rather than jockeys and says that management doesn’t matter
          as much if the structural characteristics of industry itself are not strong. 9/10 times
          the competitive dynamic of an industry will have a much greater impact on whether a
          company has an economic moat than any managerial decision. Quotes Buffett - who said “when
          management with a reputation for brilliance tackles a business with a reputation for bad
          economics, it is the reputation of the business that remains intact” – to drive home his
          point. A wide moat company managed by an average CEO will give you better odds of long run
          success than a no-moat company managed by a superstar. There are exceptions, however, to
          this rule - Starbucks for instance.
        </p>

        <h3 class="mt-4">Valuing the company on the following parameters is important:</h3>
        <ul>
          <li>How much cash will it generate (growth);</li>
          <li>The certainty attached to those cash flows (risk);</li>
          <li>The amount of investment needed to run the business (return on capital); and</li>
          <li>The amount of time the company can keep its competitors at bay (economic moat).</li>
        </ul>

        <h3>Also gives some pointers on when to sell:</h3>
        <ul>
          <li>
            If one has made a mistake in analyzing the company and the original reason is no longer
            valid;
          </li>
          <li>If the fundamentals of the company have changed permanently for the worse;</li>
          <li>
            If there is a significant supercheap stock available, one can sell a modestly
            undervalued stock to fund the purchase of the supercheap stock only if the upside is x
            times more not a few % more;
          </li>
          <li>
            Depending on one’s risk tolerance, if the stock becomes a huge part of the portfolio.
          </li>
        </ul>
      </div>
    </HeaderResources>
  )
}
